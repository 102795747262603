import React from "react";

type Props = {
    skills: string[]
}

function SkillsPills(props: Props){
    if(props.skills.length === 0){
        return null
    }
    return(
        <div className="flex items-center pt-2">
            <p className="text-text-400 text-sm pr-2 ">
                Skills:
            </p>
            <div className="flex gap-3 flex-wrap">
                {props.skills.map((skill: string)=>{
                    return <p key={skill} className="glow text-text-def text-sm border border-primary-500 rounded py-0.5 px-2 text-center 
                    hover:bg-primary-500 hover:bg-opacity-60 cursor-default">
                        {skill}
                    </p>
                })}
            </div>
        </div>
    )
}

export default SkillsPills;