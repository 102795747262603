import Project from "../models/Project";
import { html, css, js, ts, csharp, python, java,  dotnetCore, react, tailwind, bootstrap, blazor, maui, sqlite, mysql, stripe, xaml, winForm, javaFx, firebase} from "./CreateLinks";
import ticket from "../images/svgs/ticket.svg";
import spotify from "../images/svgs/spotify.svg"
import event from "../images/svgs/event.svg"
import weather from "../images/svgs/weather.svg"
import car from "../images/svgs/car.svg"
import inventory from "../images/svgs/inventory.svg"
import calculator from "../images/svgs/calculator.svg"
import service from "../images/svgs/service.svg"
import news from "../images/svgs/news.svg"
import keyboard from "../images/svgs/keyboard-key.svg"
import food from "../images/svgs/food.svg"
import juud from "../images/svgs/juud.svg"



export const projects: Project[] = [
    new Project(
        0, "MM...FOOD", "Personal Project", 
        [csharp, dotnetCore, blazor, tailwind],
        "MM...FOOD is a cooking companion! Generate recipes by ingredients, discover random recipes, and find recipes seamlessly. Unlock a world of flavors effortlessly with Spoonacular API",
        food, food
    ),

    new Project(
        12, "AL-Juud Group Website", "Mini Project", 
        [react, ts, js, tailwind, html, css],
        "A Portfolio website for AL-Juud Group",
        juud, juud
    ),

    new Project(
        1, "Ticket Ease", "University Project", 
        [csharp, dotnetCore, mysql, css, html, bootstrap, stripe],
        "My graduation project was a web app for generating and buying tickets, with admin specific actions, and allowing users to resell the tickets they buy and having payments all done through Stripe.",
        ticket, ticket
    ),

    new Project(
        2, "Music Recommendation System", "Personal Project", 
        // [python, react, restApi, tailwind],
        [python, react, tailwind],
        "The Music Recommendation System is a system for recommending music to users based on their taste, which is by providing x number of songs to the system, the AI model creates clusters of songs from the dataset, the system gets the songs information from Spotify's API and gets recommendations based on the clusters. Libraries used: Numpy, Sklearn, Pandas, and Spotipy.",
        spotify, spotify    
    ),  

    new Project(
        10, "Event Management Frontend", "Personal Project", 
        [ts, react, tailwind, css, html, firebase],
        "I held the frontend side of an on-hold project, event management, was responsible for the UI/UX design and applying them, and connecting to the backend using an API.",
        event, event
    ),

    new Project(
        3, "News Categorizer", "University Project",
        [python],
        "A project for categorizing news articles and bodies into one of ten categories.",
        news, news
    ),

    new Project(
        4, "Service Listing", "University Project",
        [csharp, maui, xaml, sqlite],
        "A mobile app that allows users to post their services and fees and other users to book those services.",
        service, service
    ),

    new Project(
        5, "Inventory Management System", "University Project",
        [csharp, dotnetCore, mysql, css, html, bootstrap],
        "A web app for ordering and monitoring item stocks.",
        inventory,inventory
    ),

    new Project(
        6, "Weather app" , "Mini Project",
        [csharp, maui, xaml, sqlite],
        "A simple weather app with city selection using AccuWeather API.",
        weather, weather
    ),

    new Project(
        7, "Vehicle Review" , "University project",
        [java, mysql, javaFx],
        "A java project that allows users to review different vehicles.",
        car, car
    ),
    new Project(
        8, "Calculator app" , "Mini Project",
        [csharp, maui, xaml],
        "A simple calculator app with simple addition, subtraction, multiplication and division operations.",
        calculator, calculator
    ),
    new Project(
        9, "Button Spammer" , "Mini Project",
        [csharp, winForm],
        "A simple designed to spam keyboard keys.",
       keyboard, keyboard
    ),
]