import React, { useEffect, useState } from "react"
import ProjectCard from "./ProjectCard";
import { projects } from "../services/CreateProjects";
import Project from "../models/Project";
export default function Projects(){

    function createProjectCards(project: Project){
        return <ProjectCard key={project.id} project={project}/>
    }

    const [activeElement, setActiveElement] = useState('ALL');
    const [activeProjects, setActiveProjects] = useState(projects);

    // const changeWindow = (elementId: string) => {
    //     setActiveElement(elementId);
    //     if(activeElement != "ALL"){
    //         setActiveProjects(projects.filter(project => project.category === activeElement));
    //     }else{
    //         setActiveProjects(projects);
    //     }
    //     console.log(activeElement, activeProjects);
    // };

    useEffect(() => {
        if (activeElement !== 'ALL') {
          setActiveProjects(projects.filter(project => project.category === activeElement));
        } else {
          setActiveProjects(projects);
        }
        console.log(activeElement, activeProjects);
      }, [activeElement]);

    return(
        <section id="projects" className="flex flex-col items-center w-full mb-32 pt-16">
            
            <div className="w-screen min-h-screen  px-4 py-4 flex flex-col items-center">
                
                <div className="relative flex justify-center mb-12">
                    <h1 className="text-center text-accent-def text-5xl font-bold py-4 px-10 rounded-tl-lg rounded-tr-lg">Projects</h1>
                    <div className="absolute bg-white h-0.5 w-1/3 bottom-0"></div>
                </div>
        
                <div>
                    <ul className="text-xl flex gap-4 xs:gap-10 text-accent-def justify-between text-center">
                        <li className={`${activeElement === "ALL" ? "text-primary-500 hover:opacity-80" : "hover:text-accent-500"} cursor-pointer`} onClick={() => setActiveElement('ALL')}>
                            All
                        </li>
                        <li className={`${activeElement === "Personal Project" ? "text-primary-def hover:opacity-80" : "hover:text-accent-500"} cursor-pointer`} onClick={()=>setActiveElement('Personal Project')}>
                            Personal
                        </li>

                        <li className={`${activeElement === "University Project" ? "text-primary-def hover:opacity-80" : "hover:text-accent-500"} cursor-pointer`} onClick={()=>setActiveElement('University Project')}>
                            University
                        </li>
                        <li className={`${activeElement === "Mini Project" ? "text-primary-def hover:opacity-80" : "hover:text-accent-500"} cursor-pointer`} onClick={()=>setActiveElement('Mini Project')}>
                            Mini
                        </li>
                    </ul>
                </div>

                <div className="flex  gap-4 justify-around flex-wrap my-12 sm:flex-row lg:container lg:mx-auto">
                    {activeProjects.map(createProjectCards)}
                    
                </div>
            </div>
        </section>
    );
}