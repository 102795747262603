class Experience {
    id: number;
    title: string;
    company: string;
    location: string;
    tasks: string[];
    skills: string[];
    startingDate: Date;
    endingDate: Date;

constructor(
    id: number,
    title: string,
    company: string,
    location: string,
    tasks: string[],
    skills: string[],
    startingDate: Date,
    endingDate: Date
  ) {
    this.id = id;
    this.title = title;
    this.company = company;
    this.location = location;
    this.tasks = tasks;
    this.skills = skills;
    this.startingDate = startingDate;
    this.endingDate = endingDate;
  }
}




export default Experience;