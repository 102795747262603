import React, { useEffect, useState } from "react";
import Experience from "../models/Experience";
import { experiencesFixed } from "../services/CreateExperiences";
import ExperienceCard from "./ExperienceCard";

export default function Experiences(){
    ////Changes to make to github
    const [experiences, setExperiences] = useState<Experience[] | undefined>([]);

    //Fetch Experience data from db
    async function fetchData() {
        try {
            // const response = await fetch("https://localhost:7072/api/Experiences", { method: "GET" });
            
            // if(response.ok){
            //     const data: Experience[] = await response.json();
            //     setExperiences(data);
            // }else{
            // }
                
            setExperiences(experiencesFixed);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(()=>{
        fetchData();
    },[])
    return(
        <section id="experience" className="flex flex-col items-center w-full pt-16">
                {/* <h1 className="text-center text-accent-def text-5xl font-bold bg-accent-900 py-4 px-10 rounded-tl-lg rounded-tr-lg">Experience</h1> */}
            <div className="relative flex justify-center mb-8">
                <h1 className="text-center text-accent-def text-5xl font-bold py-4 px-10 rounded-tl-lg rounded-tr-lg">Experience</h1>
                <div className="absolute bg-white h-0.5 w-1/3 bottom-0">
                
                </div>
            </div>
                {/* <div className="w-full min-h-screen bg-accent-900 rounded-lg px-4 py-12 flex flex-col items-center"> */}
                <div className="w-full min-h-screen rounded-lg px-4 py-12 flex flex-col items-center">
                    {experiences && experiences.length > 0 ? (
                        experiences.map((experience: any)=>{
                            return <ExperienceCard experience={experience} key={experience.id}/>
                        })

                    ) : (
                        <p className="text-text-def text-center">Loading...</p>
                    )}
                </div>
        </section>
    );
}