import React, { useEffect, useState } from "react";
import Project from "../models/Project";

type Props ={
    project: Project;
}


export default function ProjectCard(props: Props){

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    return(
        <>
            <div className="group border w-32 h-32 sm:w-48 sm:h-48 md:w-64 md:h-64 xl:h-80 xl:w-80 min-h-full relative flex flex-col justify-around items-center gap-4">
            {/* <div className="group border w-96 h-96 relative flex flex-col justify-around items-center gap-4"> */}

                <img className="absolute rounded min-w-64 w-1/2 h-1/2 -z-20 group-hover:brightness-50 transition-all ease-in-out duration-500 object-cover invert" 
                    src={props.project.thumbnail} alt={props.project.name}>
                </img>
                <div className="absolute -z-10 w-full h-full bg-black group-hover:opacity-90 opacity-0  transition-all ease-in-out duration-500">

                </div>
                <p className="opacity-0 text-accent-def group-hover:opacity-100 transition-opacity ease-in-out  duration-700 text-sm sm:text-base md:text-xl text-center outlined shadow-2xl font-bold">
                    {props.project.name}
                </p>

                <button className="opacity-0 text-text-def group-hover:opacity-100 border border-white py-1 px-2 sm:py-2 sm:px-4 transition-opacity ease-in-out duration-700 shadow-2xl hover:text-accent-300 hover:border-accent-300"
                    onClick={toggleModal}>
                        Learn more 
                        {/* &#x2197; */}
                </button>
            </div>

            {showModal && (
                //whole screen
                <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-40" onClick={toggleModal}>
                    <div className="lg:w-1/3 flex flex-col z-50">
                        {/*Top Part */}
                        <div className="flex justify-between">
                            <p className="lead text-white font-bold outline-1 outline-slate-600">{props.project.name}</p>
                            <button className="text-white self-end mr-1 text-2xl" onClick={toggleModal}>X</button>
                        </div>

                        {/* Bottom Part */}
                        <div className="rounded-md bg-background-900 text-text-def">
                            <img src={props.project.imgUrl} alt={props.project.name} className="w-full mb-2 max-h-96 object-scale-down invert" />
                            
                            <div className="p-4">
                                <label className="font-bold" htmlFor="">Tech Used</label>
                                <ul className="text-text-def text-center mb-4 text-xl flex gap-4 mt-2 flex-wrap">
                                    {props.project.toolsUsed.map((image, index)=>(
                                        <li key={index}>
                                            <img src={image.url} alt={image.alt} className="w-[40px]" />
                                        </li>
                                    ))}
                                </ul>
                                <label className="font-bold" htmlFor="">Description</label>
                                <p className="text-sm">{props.project.description}</p>
                                {
                                    props.project.name === "AL-Juud Group Website" && 
                                    <a className="text-sm text-primary-def hover:opacity-80" target="_blank" rel="noreferrer" href="https://aljuudgroup.com">AL-Juud Group</a>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
                // <div className="fixed inset-0 w-screen h-screen text-text-def flex justify-center border-2 border-text-def">
                //     <button onClick={()=>setShowModal(false)}>close</button>
                // </div>
            )}

        </>
    );
}