import React from "react";
import Experience from "../models/Experience";
import SkillsPills from "./SkillsPills";

type Props={
    experience: Experience;
}

function ExperienceCard(props: Props){
    var exp = props.experience;
    var startingDate = new Date(exp.startingDate).toLocaleDateString(undefined, {month: 'short', year:'numeric'})

    var ed = new Date(exp.endingDate)
    var endingDate = (ed.getFullYear() === 1) ? "Present" : ed.toLocaleDateString(undefined, {month: 'short', year:'numeric'})
    
    return(
        <div className="h-2/3 lg:w-2/3 w-full relative my-2">
            {/* <div className="w-full h-full bg-background-def rounded blur-md absolute"></div> */}

            <div className="z-30 relative py-8 md:px-14 xs:px-4">
                <p className="text-3xl text-primary-500">{exp.title}</p>
                <p className="text-text-def text-xl">{exp.company}</p>
                <p className="text-text-400 text-sm">{startingDate} - {endingDate}</p>
                <p className="text-text-400 text-sm">{exp.location}</p>
                <SkillsPills skills={exp.skills}/>
                <ul className="list-disc pt-2">
                {
                    exp.tasks.map((task:string)=>{
                        return <li className="text-text-def" key={task}>
                                    {task}
                                </li>
                    })
                }
                </ul>
            </div>
        </div>
    );
}

export default ExperienceCard;