import Experience from "../models/Experience";
export const experiencesFixed: Experience[] = [
    
    new Experience(
      3,
      'Software Developer',
      'CuneiCode',
      'Baghdad, Iraq · Remote',
      [
        'Developed responsive web applications for optimal user experience.',
        'Collaborated with design team to implement pixel perfect UI/UX designs for web and mobile applications.',
        'Wrote testable code. Additionally, I utilized Playwright to create comprehensive test suites.',
        'Conducted testing and debugging to ensure code quality, functionality and browser compatibility.',
        'Stay in the loop and monitor backend and DevOps teams work for learning opportunities.',
        'Contribute to the improvement of development processes and methodologies to enhance team productivity and product quality.'
      ],
      ['TypeScript', 'Tailwind', 'React.js', 'API', "Playwright", "React Native", "Next.js"],
      new Date('2024-02-01'),
      new Date('0000-01-01')
    ),
    new Experience(
      3,
      'Frontend Developer',
      'Smart Wave',
      'Baghdad, Iraq · Remote',
      [
        'Design the user interface (UI) and user experience (UX) for web applications and websites.',
        'Implement front-end designs',
        'Integrate front-end components with backend through API calls, ensuring data flow and functionality.',
        'Implement input field text validation practices using RegEx to reduce user errors.',
        'Stay updated on industry trends and emerging technologies to enhance the front-end development process.',
        'Editing and implementing new features in WordPress'
      ],
      ['React.js', 'Tailwind', 'TypeScript', 'API', "WordPress"],
      new Date('2023-06-01'),
      new Date('2024-02-01')
    ),
  
    new Experience(
      4,
      'Software Engineering Intern',
      'Moonbeam – Stedman Capital Group',
      'Columbus, Ohio Metropolitan Area · Remote',
      [
        'Conduct research in machine learning, exploring various architectures and algorithms',
        'Assist in the development and training of machine learning models for specific tasks and datasets.',
        'Optimize SQL queries to enhance database performance and efficiency'
      ],
      ['Python', 'PyTorch', 'SQL'],
      new Date('2023-02-01'),
      new Date('2024-02-01')
    ),
  
    new Experience(
      5,
      'QA Testing Intern',
      'Eurasian Universities Union - EURAS',
      'Istanbul, Turkey · On-site',
      [
        'Assist in the creation and execution of test plans and test cases for software applications.',
        'Participate in the identification of test scenarios, test data, and acceptance criteria.',
        'Learn and apply QA best practices and testing methodologies.',
        'Document and report software defects, including clear steps on how to reproduce the issues.'
      ],
      ['Automation Testing', "Communication skills", "Finding bugs", "Manual testing"],
      new Date('2022-10-01'),
      new Date('2023-01-01')
    ),
  
    new Experience(
      6,
      'Technical Support Intern',
      'Istanbul Aydin University',
      'Istanbul, Turkey · On-site',
      [
        'Provide technical support to instructors using online course platforms.',
        'Assist teachers with setting up and managing their online courses.',
        'Attend online classes to monitor for any potential issues.',
        'Document occurred issues and report them to IT team.'
      ],
      ["Troubleshooting", "Problem solving", "Analysis of technical issues"],
      new Date('2022-02-01'),
      new Date('2022-02-01')
    )
  
  ];