type tool = {
    url: string;
    alt: string;
}

export default class Project{
    
    id: number;
    name: string;
    category: string;
    toolsUsed: tool[];
    description: string;
    imgUrl: string;
    thumbnail: string;

constructor(
    id: number,
    name: string,
    category: string,
    toolsUsed: tool[],
    description: string,
    imgUrl: string,
    thumbnail: string
    ) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.toolsUsed = toolsUsed;
    this.description = description;
    this.imgUrl = imgUrl;
    this.thumbnail = thumbnail;
    }
    
    
}